

import React, { useState, useEffect } from "react";
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import parse from "html-react-parser"

import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
//import { removeDataAttributes } from "../../comQueryStructure";

const PropertyFooter = (props) => {
    console.log(props,"prosps");
const location = useLocation();
const parts = location.pathname.split('/');
const Area = parts[3] ? parts[3].substring(3).replace(/-/g, ' ') : "";
const type = parts[4] ? parts[4].substring(5) : "";
console.log(Area,"Area");
console.log(type,"type");
console.log(props.Action,"props.Action");
const data = useStaticQuery(graphql`
query {
    glstrapi {
    globalModule {
      Property_Detail_SEO {
        Action
        Area
        Content
        Property_Type
        Type
        id
        _id
       }
      }
    }
  }
`)
//const seo_data = removeDataAttributes(data)
const footerData = data.glstrapi.globalModule.Property_Detail_SEO
//Below logic is for property_Type null or empty
const filterFooter = footerData?.filter(data => {
    if(!data.Property_Type) {
        data.Property_Type = ""
    }
    if(!data.Area) {
      data.Area = ""
  }
    return  data.Action === props.Action &&
    data.Area === Area && 
    data.Property_Type === type
})
console.log("footerData",footerData)

    return (
      <Container>
        {filterFooter?.length > 0 && filterFooter[0].Content && 
        <div class="property-bottom-footer">{parse(filterFooter[0].Content)}</div> 
    
        }
       

     </Container>
	)
}
export default PropertyFooter;
