import { Link } from "@StarberryUtils";
import * as React from "react"
//import ScrollAnimation from "react-animate-on-scroll";
import { Button, Container, Row, Col } from "react-bootstrap"
import mobimg from "../../../images/development/mob-img.png";
import tabimg from "../../../images/development/tab-img.png";
import deskimg from "../../../images/development/desk-img.png";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

import "./Development.scss"

// markup
const Development = (props) => {

    const property = props.data;

    const ImageRenderList = ({item, imagename, id}) => {
        let processedImages = JSON.stringify({});
        if (property?.imagetransforms?.Tile_Image_Transforms) {
            processedImages = property.imagetransforms.Tile_Image_Transforms;
        }
        return (
            <ImageTransform
                imagesources={item?.Tile_Image?.url}
                renderer="srcSet" imagename={imagename}
                attr={{ alt: `${item?.Title} - Dacha`, className: '' }}
                imagetransformresult={processedImages}
                lazyLoading={false}
                id={id}
            />
        )
    }

    return (
        <React.Fragment>
            <section className={`development ${props.type}`}>
                <div animateIn='fadeInUp' animateOnce>
                    <Container>
                        {props.type == "latest" &&
                            <div className="heading">
                                <h2>Latest Off Plan Development</h2>
                                <Link to={`/off-plan-properties`} className="btn btn-secondary d-none d-md-flex">  More Developments</Link>
                            </div>
                        }
                        <Row>
                            <Col className="listing-properties">
                                <div className="development-wrapper">
                                    <div className="development-img">
                                    <Link to={`/off-plan-properties/${property.URL}`}>
                                            {
                                                property.Property_Tag &&
                                                <div className="property-tag">{property.Property_Tag}</div>
                                            } 
                                           <ImageRenderList item={property} imagename={"off-plan-properties.Tile_Image.tile"} id={property?.id}/>
                                        </Link>

                                    </div>
                                    <div className="development-info">
                                        <h3>
                                            <Link to={`/off-plan-properties/${property.URL}`}>
                                                {property.Title}
                                            </Link>
                                        </h3>
                                        <div className="price-info">
                                            <span className="title">
                                                Prices from :&nbsp;
                                            </span>
                                            <span className="total-price">
                                               AED&nbsp;{property.Price.toLocaleString()}
                                            </span>
                                        </div>
                                        <div className="about-info">
                                            <span className="sm-text">
                                                Location :
                                            </span>
                                            <span className="sm-content">
                                                {property.Location}
                                            </span>
                                        </div>
                                        <div className="about-info">
                                            <span className="sm-text">
                                                Developer :
                                            </span>
                                            <span className="sm-content">
                                                {property.Developer}
                                            </span>
                                        </div>
                                        <div className="about-info">
                                            <span className="sm-text">
                                                Development Type :
                                            </span>
                                            <span className="sm-content">
                                                {property.Developer_Type}
                                            </span>
                                        </div>
                                        <p>
                                            {property.Short_Description}
                                        </p>
                                        <div className="button-class">
                                            {props.type === "latest" &&
                                                <Link to={`/off-plan-properties/${property.URL}`} className="btn btn-secondary">
                                                    View Property Details
                                                </Link>
                                            }
                                            {props.type !== "latest" && 
                                                <Link to={`/off-plan-properties/${property.URL}`} className="btn btn-secondary">
                                                    View Property Details
                                                </Link>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {props.type == "latest" &&
                            <div className="button-class development-btn">
                                <Link to={`/off-plan-properties`} className="btn btn-secondary">    More Developments  </Link>
                            </div>
                        }
                    </Container>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Development