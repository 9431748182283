/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { ACTION_TYPES, getPriceStr } from "./utils"
export { ACTION_TYPES, ACTION_DESC_TYPES, getPriceStr } from "./utils";

const SeoResults = ({ image_url, description, lang, meta, title, searchParams, location, pcategorytype }) => {
  const { areas, bedrooms, pType, type, price, commType } = searchParams;

  const actionStr = ACTION_TYPES[pType];
  const priceStr = getPriceStr(price)
  const bedRooms = searchParams.bedrooms

  let propertyType = ""

  if (type == "") {
    propertyType = "Property "
  } else {
    propertyType = "Property " + type
  }

  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  // let desc = "";  
  // console.log(propertyType)
  // desc = "Discover the wide range of "+ propertyType + actionStr + areas +" with Dacha Real Estate.Book an appointment with one of our property expert today to find the perfect property "+ pType+ " anywhere in " + areas + ".";
  // desc = `Looking for ${capitalize(pcategorytype)} ${capitalize(propertyType)}${actionStr}${areas}. Dacha real estate property experts are here to help with all your property needs.`

  // if ('dubai' !== areas) {
  //   desc = "Discover a wide range of "+ propertyType + actionStr + areas +" with Dacha. To filter these results, use our filter below, or for more information about "+ pType+ " property in " + areas + ". please contact us.";
  // }

  // if ('sales' === pType) {
  //   desc = "Discover a wide range of "+ propertyType + actionStr + areas +" with Dacha. To filter these results, use our filter below, or for more information about "+ pType+ " property in " + areas + ". please contact us.";

  //   if ('dubai' !== areas) {
  //       desc = "Discover a wide range of "+ propertyType + actionStr + areas +" with Dacha. To filter these results, use our filter below, or for more information about "+ pType+ " property in " + areas + ". please contact us.";
  //   }
  // } else {
  //   // price
  //   if (price.min || price.max){
  //     desc = propertyType + actionStr + areas + "."
  //   }

  // }

  //
  let titlePropertyType = ""

  if (type == "") {
    titlePropertyType = "Property"
  } else if (type == "apartment") {
    titlePropertyType = "Apartments"
  } else if (type == "house") {
    titlePropertyType = "Houses"
  } else if (type == "maisonette") {
    titlePropertyType = "Maisonette"
  } else if (type == "property") {
    titlePropertyType = "Property"
  } else if (type == "bungalow") {
    titlePropertyType = "Bungalow"
  } else if (type == "land") {
    titlePropertyType = "Land"
  } else if (type == "commercial") {
    titlePropertyType = "Commercial"
  } else {
    titlePropertyType = type
  }

  let bedroomType = ""

  if (bedrooms) {
    bedroomType = bedrooms + ' + Bedroom '
  }

  let descTitle = bedroomType + capitalize(titlePropertyType) + actionStr + capitalize(areas) + priceStr
  //

    const metaImg = image_url?image_url:`https://ggfx-dachare.s3.eu-west-2.amazonaws.com/i.prod/dacha_logo_a4c52644e6.png`

  var currentURL = '';
  if (location !== undefined && location.pathname) {
    currentURL = process.env.GATSBY_SITE_URL + location.pathname;
  }

  if (!currentURL.endsWith('/'))
    currentURL = currentURL + '/'

  let metaItems = [
    {
      name: `description`,
      content: description,
    },
    {
      property: `og:title`,
      content: descTitle,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      property: `og:url`,
      content: currentURL,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: `Dacha`,
    },
    {
      name: `twitter:title`,
      content: descTitle,
    },
    {
      name: `twitter:description`,
      content: description,
    },
    {
      name: `facebook-domain-verification`,
      content: 'vdkffx5ct5qyun4d508t0d75w06p2I',
    },
    {
      name: `twitter:image`,
      content: metaImg,
    },
    {
      property: `og:image`,
      content: metaImg,
    },
  ].concat(meta);


  // set no index for result pagination

  console.log("location_pathname", location, location.pathname);

  let hasPagination = false;
  // if (location !== undefined && location.pathname) {
  //   let pageStr = location.pathname.split('page-');
  //   hasPagination = pageStr[1] || false;
  // }

  if (location.pathname !== undefined) {
    let pageStr = location.pathname.split('page-');
    hasPagination = pageStr[1] || false;
  }

  if (hasPagination) {
    var the_arr = location.href.split('/');
    the_arr.pop();
    the_arr =  the_arr.join('/')

    if (!the_arr.endsWith('/'))
      currentURL = the_arr + '/'

    // metaItems.push({
    //   name: `robots`,
    //   content: `noindex`,
    // })
  }

  // const urlpath = [
  //   'properties/commercial/for-sale',
  //   'properties/commercial/for-rent',
  //   'properties/for-sale',
  //   'properties/for-rent'
  // ];
  
  const urlpath = [
    'properties/for-sale/in-palm-jumeirah/type-apartment/in-dubai/',
    'properties/for-sale/in-dubai-marina/type-apartment/1-and-more-bedrooms/in-dubai/',
    'properties/for-sale/in-dubai-marina/type-apartment/in-dubai/'
  ];
  if (urlpath.some(propurl => location.pathname.includes(propurl))) {
    // const canonicalUrlElement = document.querySelector('link[rel="canonical"]');
    let currentURL = window.location.href; 
    console.log("url--", currentURL);
    
    currentURL = currentURL.replace(/\/$/, '');
    const urlSegments = location.pathname.split('/').filter(Boolean);
    // if (urlSegments.length > 4 && currentURL.endsWith('/in-dubai')) {
    //   currentURL = currentURL.slice(0, -9);
      
    // }
    if (urlSegments.length > 4 && currentURL.endsWith('/in-dubai')) {
        metaItems.push({
          name: `robots`,
          content: `noindex`,
        })
    }else {
      metaItems.push({
        name: `robots`,
        content: `index`,
      })
      var canonical = [
        {
          href: currentURL,
          rel: "canonical"
        }
      ];
    }

    // var canonical = [
    //   {
    //     href: currentURL,
    //     rel: "canonical"
    //   }
    // ];
  }
  
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={descTitle}
      titleTemplate={`${title} | Dacha Real Estate`}
      link={canonical}
      meta={metaItems}
    >

    </Helmet>
  )
}

SeoResults.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: ``,
  searchParams: [],
}

SeoResults.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  searchParams: PropTypes.any,
}

export default SeoResults
